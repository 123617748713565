/*
 *
 *  * Copyright 2022 GHGSat inc.
 *  * Authors: spectra@ghgsat.com
 *  * This software is not for distribution outside GHGSat organization
 *
 */

import React from "react";
import { Locales } from "../../../core/constants/Localize";
import { NavLink } from "react-router-dom";
import { routes } from "../../../core/routes/Routes";
import "./LoginCreateBar.scss"
import { useSelectLangSelector } from "../../../core/reducers/lang.slice";

const LoginCreateBar = ({isNewStickerShown= false}) => {
  const currentLanguage = useSelectLangSelector();

  return (
    <div className="form-titles-container">
      <NavLink to={routes.login.path} end className={({isActive}) => `form-title-container ${isActive ? '' : 'not-selected'}`}>
        <h3 className="form-title">{Locales.LogIn}</h3>
        <hr className="form-title-underscore" />
      </NavLink>
      <NavLink to={routes.createAccount.path} className={({isActive}) => `form-title-container ${isActive ? '' : 'not-selected'}`}>
        <h3 className="form-title">{Locales.CreateAccount}</h3>
        <hr className="form-title-underscore new" />
        {isNewStickerShown &&
          <div className={currentLanguage === 'fr' ? "new-sticker-fr" : "new-sticker-en"}>{Locales.NEW}</div>
        }
      </NavLink>
    </div>
  );
};

export default LoginCreateBar;
