/*
 * Copyright 2024 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { TypedUseSelectorHook, useSelector } from 'react-redux';
import { RootState } from '../reducers/store';

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
