/*
 * Copyright 2024 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Locales } from '../constants/Localize';
import { LANG } from '../constants/Constants';
import Cookies from 'js-cookie';
import { useAppSelector } from '../hooks/useAppSelector';


interface LangSlice {
  lang: string;
}

const initialLang = Cookies.get('lang') || Locales.getLanguage() || LANG.en;

const initialState: LangSlice = {
  lang: initialLang,
};

export const langSlice = createSlice({
  name: 'lang',
  initialState,
  reducers: {
    changeLang: (state, action: PayloadAction<string>) => {
        Locales.setLanguage(action.payload);
        state.lang = action.payload;
        Cookies.set('lang', action.payload);
    },
  },
});

export const { changeLang } = langSlice.actions;

// The function  below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state: RootState) => state.counter.value)`
export const useSelectLangSelector = () => useAppSelector(({ lang }) => lang.lang);

export default langSlice.reducer;
