/*
 * Copyright 2024 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { useCallback, useEffect, useState } from 'react';
import { useSelectLangSelector } from '../reducers/lang.slice'

// TODO https://gitlab.ghgsat.com/1511/spectra/-/issues/2481
const useLocalesReload = () => {
  const currentLanguage = useSelectLangSelector();
  const [, updateState] = useState();
  const forceUpdate = useCallback(() => updateState({} as any), []);

  useEffect(() => {
    forceUpdate();
  }, [currentLanguage, forceUpdate]);
};

export default useLocalesReload;
