/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import ReactGA from 'react-ga4';
import { GaOptions, UaEventOptions } from 'react-ga4/types/ga4';
import { info } from '../utils/Debug.utils';

interface InitializerOptions {
  legacyDimensionMetric?: boolean;
  nonce?: string;
  testMode?: boolean;
  gaOptions?: GaOptions | any;
  gtagOptions?: any;
}

let isInitialized = false;
let isInDevMode = false;

const initialize = (measurementId: string, options?: InitializerOptions): void => {
  ReactGA.initialize(measurementId, options);
  isInitialized = true;
};

/**
 * In dev mode, events are not sent to GA
 */
const initializeDevMode = () => {
  isInDevMode = true;
  info('GA initialized in dev mode');
};

const pushEvent = (event: UaEventOptions): void => {
  if (isInitialized) {
    ReactGA.event(event);
  }
  if (isInDevMode) {
    info('[GA4 push event]');
    info(event);
  }
};

const set = (data: Record<string, string | number | null>): void => {
  if (isInitialized) {
    ReactGA.set(data);
  }
  if (isInDevMode) {
    info('[GA4 set]');
    info(data);
  }
};

export const analyticsClient = {
  initialize,
  initializeDevMode,
  pushEvent,
  set,
};

export enum EventCategory {
  Login = 'Login',
  TermsOfService = 'TermsOfService',
}

export const EventActions = {
  Login: {
    Complete: 'Complete',
  },
  TosModal: {
    Complete: 'Complete',
  },
};
