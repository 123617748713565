/**
 *
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 *
 */

import { ReactElement } from "react";
import { Locales } from "../../../core/constants/Localize";
import logo from "../../assets/logo/logo-ghgsat-full-color.svg";
import LogoSpectra from "../../assets/logo/logo-spectra.svg";
import HeroSection from "../HeroSection/HeroSection";
import ScheduleMaintenanceWarning from "../ScheduledMaintenanceWarning/ScheduledMaintenanceWarning";
import "./PublicPage.scss";
import { isBrowser, isTablet } from "react-device-detect";
import LanguageDropdown from "../../pages/Login/LanguageDropDown";

const appVersion = process.env.REACT_APP_VERSION;

interface LocalProps {
  children: ReactElement;
}

const PublicPage = ({ children }: LocalProps) => (
  <div className="public-page with-form">
    <div className="public-page-container with-form">
      {!isBrowser && !isTablet ? "" : 
        <div className="left">
          <HeroSection />
        </div>
      }
      <div className="right">
      <LanguageDropdown/>
        {Locales.ScheduledMaintenance?.Item1 !== "" && (
          <ScheduleMaintenanceWarning />
        )}
        <main className="public-page-header">
          <div className="public-page-logo">
            <img src={LogoSpectra} alt="Spectra by GHGSat" />
          </div>

          <div className="public-page-form">{children}</div>
        </main>

        <footer className="public-page-footer">
          <div className="disclosure">
            <p className="powered-by">
              V {appVersion} - {Locales.poweredBy}
            </p>
            <a
              href={Locales.GhgSatUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={logo} alt="GHGSAT" />
            </a>
          </div>
        </footer>
      </div>
    </div>
  </div>
);

export default PublicPage;
