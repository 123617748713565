/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { Locales } from "../../../core/constants/Localize";
import { useSelectLangSelector } from "../../../core/reducers/lang.slice";
import Priv_pdf_en from "../../assets/pdfs/PrivacyPolicy_en.pdf";
import Priv_pdf_fr from "../../assets/pdfs/PrivacyPolicy_fr.pdf";
import TOUpdf from "../../assets/pdfs/Terms of Use_en.pdf";
import ModalBody from "../Reusable/ModalWindow/ModalBody";
import ModalFooterButtons from "../Reusable/ModalWindow/ModalFooterButtons";
import ModalWindow from "../Reusable/ModalWindow/ModalWindow";
import TextButton from "../Reusable/TextButton/TextButton";
import "./TOSModal.scss";

interface LocalProps {
  open: boolean;
  loading: boolean;
  accept: () => void;
  cancel: (visibility: boolean) => void;
}

const TOSModal = ({ open, loading, accept, cancel }: LocalProps) => {
  const language = useSelectLangSelector();

  const handleCancelClick = () => {
    // can't login - close modal.
    cancel(false);
  };

  const handleAgreeClick = () => {
    accept();
  };

  return (
    <ModalWindow title={Locales.Welcome} open={open} size="md">
      <ModalBody>
        <p>{Locales.TermsOfService.FirstParagraph}</p>
        <br />
        <p>
          {Locales.TermsOfService.SecondParagraph}
          <a href={TOUpdf} target="_blank" rel="noopener noreferrer">
            {Locales.TermsofUse}
          </a>
          <span> {Locales.TermsOfService.And} </span>
          <a href={(language === 'fr') ? Priv_pdf_fr : Priv_pdf_en} target="_blank" rel="noopener noreferrer">
            {Locales.PrivacyPolicy}
          </a>
          .
        </p>
      </ModalBody>
      <ModalFooterButtons>
        <TextButton
          className="uppercase"
          type="outlined"
          onClick={handleCancelClick}
        >
          {Locales.Cancel}
        </TextButton>
        <TextButton
          className="uppercase button-filled"
          type="filled"
          loading={loading}
          onClick={handleAgreeClick}
        >
          {Locales.IAccept}
        </TextButton>
      </ModalFooterButtons>
    </ModalWindow>
  );
};
export default TOSModal;
