/**
 * /*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 *
 * @format
 */

// ES6 module syntax
import LocalizedStrings from "react-localization";

/* USAGE
  import { Locales } from '../core/Localize';
  Locales.goBackToAllSites
*/

  const englishTranslationsList = {
    AddingLayer: "Adding Layer",
    AddALayer: "Add a Layer",
    AddNewBookmark: "Add New Bookmark",
    AddNewUser: "Add new user",
    Alerts: "Alerts",
    All: "All",
    AllTime: "All time",
    Analytics: "Analytics",
    AnalyticsFrom: "Analytics From",
    Agriculture: "Agriculture",
    AircraftSensor: "Aircraft Sensor",
    AskForObservation: "Ask For Observation",
    AssetType: "Asset type",
    AvailableObservation: "Site Observation",
    AvailableOn: "Available On",
    CatalogObservation: "Catalog Observation",
    BackToLogin: "Back to Log In",
    Business: "Business",
    BuyableObservation: "Buyable Observation",
    basemap: "Basemap",
    Cancel: "Cancel",
    cart: "Cart",
    Catalog: "Catalog",
    CatalogOffering: "Catalog Offering",
    Center: "Center",
    ChangeMyPassword: "Change my password",
    Close: "Close",
    Code: "Code",
    CollapseAll: "Collapse all cards",
    Complete: "Complete",
    Concentration: "Concentration",
    Confidence: "Confidence",
    ConfidencePrediction: "Confidence Prediction",
    ConfirmNewPassword: "New Password Confirmation",
    ConfirmPassword: "Password Confirmation",
    ConfirmYourNewPassword: "Confirm your new password",
    contained: "contained",
    Coordinates: "Coordinates",
    Coord: {
      N: "N",
      E: "E",
      S: "S",
      W: "W",
    },
    Confirm: "Confirm",
    ConfirmAction: {
      ConfirmDeleteUser: (name: string) =>
        `Do you want to resend the invitation to ${name}?`,
      ConfirmResendInvite: (name: string) =>
        `Do you want to delete the user account for ${name}?`,
      ConfirmExitMyBookmarksModal: `Changes made since the last save will be lost. Do you want to proceed?`,
    },
    ContactGHGSatSalesForAZone:
      "Contact your GHGSat sales representative to purchase the data for a zone.",
    ContactGHGSatSalesForThisObs:
      "Contact your GHGSat sales representative to purchase this observation.",
    ContactGHGSatSalesForThisZone:
      "Contact your GHGSat sales representative to purchase the data for this zone.",
    ContactGHGSatSalesForAllZones:
      "Contact your GHGSat sales representative to purchase zone data.",
    CopyLink: "Copy Link",
    Country: "Country",
    CreateAccountInstructionTitle: "Fill the form to create a free account.",
    CreateAnAccount: "Create an Account",
    CreateAccount: "Create Account",
    CreateAccountConfirmation: "Account Confirmation",
    CreateAccountTerms: {
      Text: "By creating an account, you agree to our ",
      Link: "Terms and conditions",
      And: " and ",
      PrivacyPolicy: "Privacy Policy",
    },
    CreateAccountSuccess: (email: string) =>
      `We have sent an email to: ${email}. In the email you will find a code verify your account.`,
    CreateAccountCheckSpam:
      "Please check your junk and spam folder before contacting our support team.",
    CreatePassword: "Create Password",
    CreateNewPassword: "Create new password.",
    CreatePasswordProblem: "Error - The password could not be created.",
    CreatePasswordSuccess: "Your account was successfully created",
    createPasswordSubtitle: "Please enter a password",
    "create-password": "create-password",
    CsvFilePrefix: {
      EmissionsList: "emissions-list",
      ObservationList: "observation-list",
      SiteList: "site-list",
      SiteDetails: "site-details",
      ZoneEmissions: "zones-emissions",
    },
    currentlyDisplaying: "Currently Displaying",
    DataDisplay: "Data Display",
    dataLayers: "Data layers",
    DataAvailableFrom: "Data available from",
    Date: "Date",
    DateOfObservation: "Date of Observation",
    days: "Days",
    "forgot-password": "forgot-password",
    exportAsCsv: "Export as .csv file",
    DeleteView: "Delete View",
    DeleteAllViews: "Delete All Views",
    DemoData: "Demo Data",
    Disconnect: "Disconnect",
    Display: "Display",
    DisplaySiteOnMap: "Display Site On Map",
    DistanceUnit: "Distance Unit",
    DownloadCaps: "DOWNLOAD",
    DownloadFiles: "Download Files",
    DownloadListView: "Download List View",
    DownloadObservations: "Download Observations",
    DownloadAllObservations: "Download All Observations",
    DownloadReport: "Download Report",
    DownloadStarted: "Download started",
    DownloadingObservations: "Downloading Observations",
    Email: "Email",
    EmailAddress: "Email address",
    EmailAddressCap: "Email Address",
    EmissionsDetected: "Emissions Detected",
    Emission: "Emission",
    EmissionID: "Emission ID",
    Emissions: "Emissions",
    EmissionsList: "Emissions List",
    EmissionRate: "Emission Rate",
    EmittingSites: "Emitting Sites",
    EnterLayerUrl: "Enter the layer url",
    Error: "Error",
    Errors: {
      CouldNotLoadConcentration: "Could not load concentration data",
      CouldNotLoadEmissionsList: "Could not load emissions data",
      CouldNotLoadEmissionOnMap: "Could not load emission data on map",
      CouldNotLoadPremiumSatEmissionsList:
        "Could not load premium offering satellite emissions data",
      CouldNotLoadGlobalSurveyList: "Could not load global survey data",
      CouldNotLoadObservationsList: "Could not load observations data",
      CouldNotLoadObservationOnMap: "Could not load observation data on map",
      CouldNotLoadSitesList: "Could not load sites data",
      CannotFindMap: "There was some error with the map view",
      InvalidEmail: "Invalid Email",
      PasswordMatch: "Your passwords do not match",
      PasswordIncorrect: "Incorrect Password",
      PasswordComplexity: "Your password is not complex enough",
      TOSError: "Could not accept Terms of Service",
    },
    ErrorRate: "Error Rate",
    ErrorLoadingDetails: "Error loading details...",
    ErrorLoadingLayer: "Error loading layer...",
    ErrorLoadingNotifications: "Error loading notifications...",
    entries: "entries",
    Esri: "Esri",
    ExpandAll: "Expand all cards",
    Favorite: "Favorite",
    FavoriteSites: "Favorite Sites",
    favorites: {
      AddToFavorites: "Add to Favorite Sites",
      AddedToFavorites: "Added to Favorite Sites",
      RemoveFromFavorites: "Delete from Favorites",
      RemovedFromFavorites: "Deleted from Favorite Sites",
    },
    Flare: "Flare",
    Flares: "Flares",
    FlaresDetected: "Flares Detected",
    FilterByEmissionID: "Filter By Emission ID",
    FilterByID: "Filter By ID",
    FilterByObservationID: "Filter By Observation ID",
    FilterBySensor: "Filter By Sensor",
    FilterBySiteName: "Filter By Site Name",
    FilterByZone: "Filter By Zone",
    FindPlaceCityRegion: "Find Place, City, Region",
    FirstName: "First Name",
    Forecast: "Forecast",
    ForgotPassword: "Forgot Password",
    ForgotYourPassword: "Forgot your password",
    ForgotPasswordInstruction:
      "Enter your email address below\n" +
      "and we will send you a verification code in order to confirm your identity before allowing you to  reset your password.",
    ForgotPasswordSuccess: (email: string) =>
      `We have sent an email to ${email}. If you don’t receive an email, please check that you entered a valid Spectra account and resubmit.`,
    ForgotPasswordFail: "Error - Unable to initiate forgot password",
    Found: "Found",
    From: "From",
    FromTo: "From / To",
    FromZones: "From Zones",
    FromYourSites: "From Your Sites",
    GetStartedWithSpectra: "Get Started with SPECTRA",
    ghgSat: "GHGSat",
    GHGSatSensor: "GHGSat Sensor",
    GhgSatUrl: "https://www.ghgsat.com/",
    GhgSatContactUrl: "https://www.ghgsat.com/en/contact/",
    GhgSatContactEmail: "info.spectrabasic@ghgsat.com",
    GHGSatAircrafts: "GHGSat Aircrafts",
    GHGSatSatellites: "GHGSat Satellites",
    GlobalError: "An error occurred. Please try again.",
    goBackToAllSite: "Go back to all sites",
    GoBack: "Go Back",
    GoToLogin: "Go To Login",
    GoToMyProfile: "Go To My Profile",
    GoToSite: "Go To Site",
    GoToThisDisplay: "Go To This Display",
    GoToThisObservation: "Go To This Observation",
    Grid: "Grid",
    GridCell: "Grid Cell",
    Hello: "Hello",
    Help: "Help",
    Here: "here",
    hi: "Hi",
    High: "High",
    Holdbacks: "Holdbacks",
    home: "Home",
    Hotspot: "Hotspot",
    Hotspots: "Hotspots",
    HotspotDetected: "Hotspot Detected",
    IAgree: "I Agree",
    IAccept: "I Accept",
    ID: "ID",
    Id_Lower: "Id",
    IForgotMyPassword: "Forgot your password ?",
    Industry: "Industry",
    IndustryOptions:{
      AcademicResearch:"Academic & Research",
      Agriculture:"Agriculture",
      AluminiumSteel: "Aluminium & Steel",
      Cement: "Cement",
      Chemicals: "Chemicals",
      CoalMining: "Coal Mining",
      Finance: "Finance",
      Government: "Government",
      Media: "Media",
      NGO: "NGO",
      OilGas: "Oil & Gas",
      PowerGeneration: "Power Generation",
      ServicesEnvironmentalGIS: "Services - Envrionmental & GIS",
      Student: "Student",
      Transportation: "Transportation",
      WasteManagement: "Waste Management",
      Other: "Other",
    },
    insights: "Insights",
    InvalidInviteLink: {
      Title: "Invitation is expired or invalid",
      Subtitle:
        "This invitation link is expired or invalid. Contact your organization administrator or your sales representative to request a new link.",
    },
    InvalidPasswordResetLink: {
      Title: "Password reset link is expired or invalid",
      Subtitle:
        "This password reset link is expired or invalid. Contact your organization administrator or your sales representative to request a new link.",
    },
    InvisibleAtCurrentScale: "Invisible at current scale",
    InZone: "In Zone",
    JobTitle: "Job Title",
    kg: "kg",
    kt: "kt",
    kgPerH: "kg/h",
    tabHeaderOne: "Site List",
    tabHeaderTwo: "Observation list",
    tEqualKg: "1 t = 1,000 kg",
    tPerH: "t/h",
    LandmarkAlerting: "Landmark Alerting",
    Landmark: "Landmark",
    Landmarks: "Landmarks",
    Languages: {
      fr: 'Français',
      en: 'English',
    },
    Last: "Last",
    Last6Months: "Last 6 months",
    Last3Months: "Last 3 months",
    LastMonth: "Last month",
    LastName: "Last Name",
    LastWeek: "Last week",
    LastUpdate: "Last Update",
    LastUpdateOn: "Last Update on",
    Latitude: "Latitude",
    Legend: "Legend",
    LinkCopied: "Link Copied",
    Longitude: "Longitude",
    login: "login",
    Login: "Log in",
    LogIn: "Log In",
    CustomerLoginPrompt:
      "Are you a GHGSat customer under contract? Log In {here}",
    KeepLoggedIn: "Keep me logged in",
    LoginCop27: {
      Title: "Data Solutions for Climate Change",
      PreTitle: "Get started today",
      SeeEmissions: "See emissions",
      ToMitigateThem: "to mitigate them",
      KnowMore: "You would like to know more?",
      ContactUs: "Contact Us",
      ProblemLoggingIn: "Problem logging in? Contact our support team",
    },
    LoginError: "Error - login unsuccessful",
    Logout: "Logout",
    logout: "logout",
    LogOut: "Log Out",
    LoggingIn: "Logging In",
    LoggingOut: "Logging out",
    LoadingSites: "Loading Sites",
    LoadingObservations: "Loading Observations",
    LoadingEmissions: "Loading Emissions",
    Loading: "Loading",
    Low: "Low",
    ManageUsersAndRoles: "Manage users and roles",
    MandatoryFields: "*Mandatory fields",
    map: "map",
    maintenance: "maintenance",
    Maintenance: {
      Title: "SPECTRA is under maintenance",
      SubTitle: "We will be back online at 4PM ET",
    },
    MapView: "Map view",
    mapPage: "Map Page",
    Maybe: "Maybe",
    Methane: "Methane",
    MethaneEmissions: "Total Methane Emissions (last 6 months)",
    MethanesTimelines: "Methane’s timelines",
    NEW: "NEW",
    NewPasswordSuccess: "Your new password is ready to use.",
    Mining: "Mining",
    MeasurementsFrom: "Measurements From",
    Measurements: "Measurements",
    MeasurementsAdjusted: "Measurements have been automatically adjusted",
    Monitored: "Monitored",
    Month: "Month",
    MonthlyEmissionsFromZones: "Monthly Emissions From Zones",
    MonthlyEmissionsFromSites: "Monthly Emissions From Your Sites",
    Months: "Months",
    MyBookmarks: "My Bookmarks",
    MyBookmarkActions: {
      MyBookmarkSaved: "My Bookmark saved",
      MyBookmarkSaveUnsuccessful: "My Bookmark save unsuccessful",
    },
    MyDisplays: {
      MyDisplays: "My Displays",
      ChooseAsDefault: "Set as Default",
      CreateNewDisplay: "Create New Display",
      MyDefaultDisplay: " My Default Display",
      NoDisplays: "You have no displays.",
      save: {
        NewDisplayName: "New display name",
        title: "Save this display",
        fieldLabel: "Name of this display",
        placeholder: "My awesome display",
        confirmation:
          "You are about to save this display (map extent, basemap, etc.). " +
          "This display will be available in your personal menu for later use loem ipsum dolor sit amet",
        cta: "Save display",
        checkBoxLabel:
          "Choose as main display (will be displayed each time you log in the platform).",
        Success: "Display Saved",
      },
      edit: {
        title: "Edit display information",
        fieldLabel: "Display name",
        placeholder: "North America - Critical sites",
        confirmation: "confirm",
        cta: "Save CHANGES",
      },
      delete: {
        title: "Delete display",
        cta: "YES, Delete display",
        confirmation:
          "You are about to delete “North America - Critical sites”. Are you sure your want to proceed?",
        Success: "Display Deleted",
      },
    },
    MyLayers: "My layers",
    MyProfile: "My Profile",
    MyProfileActions: {
      MyProfileSaved: "My Profile saved",
      MyProfileSaveUnsuccessful: "My Profile save unsuccessful",
      PasswordSaved: "Password saved",
      PasswordSaveUnsuccessful: "Password save unsuccessful",
    },
    NA: "N/A",
    Name: "Name",
    NearbySites: "Nearby Sites",
    NewPassword: "New Password",
    NewPasswordSaved: "New password saved",
    NewPasswordSavedMessage:
      "Please use this password to login to your account from now on.",
    NextUpdate: "Next Update",
    NextWeek: "Next Week",
    NeverObserved: "Never Observed",
    No: "No",
    NoData: "No Data",
    NoEmissions: "No emissions",
    NoNearbySites: "No Nearby Sites",
    NoNotificationTITLEs: "No notifications",
    NoObservations: "No observations",
    NoEmittingSiteInArea: "No emitting sites in this area",
    NoEmissionsFound: "No Emissions Found",
    NoObservationsFound: "No Observations Found",
    NoSitesFound: "No Sites Found",
    NoSitesOfInterestInArea: "No sites of interest in this area",
    NoSitesVisibleAtThisScale: "No sites visible at this scale",
    Notifications: "Notifications",
    NotFound: {
      Title: "404",
      SubTitle:
        "Oops! We can’t seem to find the page you’re looking for. From there Earth looks like a pale blue dot.",
    },
    NoReportsFound: "No Reports Found",
    NoUsersFound: "No Users Found",
    MaximumDownload: "Maximum download of 150 observations",
    NumberOfObservations: "Number of Observations",
    NumberOfSitesWhereEmissions:
      "Number of sites where emissions have been detected in the last 7 days.",
    ObsId: "Obs ID",
    observation: "observation",
    Observation: "Observation",
    ObservationID: "Observation ID",
    ObservationDate: "Observation Date",
    ObservationEmission: "Emission",
    ObservationList: "Observation List",
    ObservationRate: "Rate",
    ObservationSensor: "Sensor",
    Observations: "Observations",
    observations: "observations",
    ObservationsFrom: "Observations From",
    ObservationFiles: "Observation Files",
    ObservationConcentrationMap: "Concentration Map",
    ObservationsInPeriod: "Observations for this period",
    Observed: "Observed",
    ObservedOn: "Observed On",
    of: "of",
    Off: "Off",
    OilGasWellsSite: "Oil & Gas - Wells Site",
    OilGasMidStream: " Oil & Gas - Mid-Stream",
    Ok: "Ok",
    On: "On",
    OnlyFeatureLayersPermitted: "Only feature layers are permitted.",
    OpenStreetMapsContributors: "© OpenStreetMap contributors",
    Organization: "Organization",
    Other: "Other",
    otherSites: "Other sites",
    OtherZones: "Other Zones",
    Password: "Password",
    PasswordHasBeenReset: "Your password has been reset",
    PossibleEmissions: "Possible emissions",
    PasswordHints: [
      "Use 15 or more characters with a mix of lowercase and uppercase letters along with numbers.",
      "Do not use your email address.",
    ],
    PasswordResetProblem: "Error - The password could not be reset.",
    Percent: "%",
    PleaseEnter: "Please enter",
    poweredBy: "Powered By",
    PowerGeneration: "Power Generation",
    ppb: "ppb",
    Prediction: "Prediction",
    Predictions: "Predictions",
    PredictionsConfidence: "Prediction Confidence",
    PredictionRisk: "Prediction Risk",
    PreviousWeek: "Previous Week",
    Rate: "Rate",
    sensorName: "Sensor",
    Remove: "Remove",
    RemoveLayer: "Remove Layer",
    Reports: "Reports",
    ResendInvitation: "Resend Invitation",
    ResetFilters: "Reset Filters",
    ResetPassword: "Reset Password",
    ResetPasswordSubtitle: "Please enter a new password.",
    ResetPasswordSuccess: "Your password has been reset.",
    Risk: "Risk",
    RiskPrediction: "Risk Prediction",
    Role: "Role",
    Roles: {
      AdminUser: "Admin User",
      ManagerUser: "Manager User",
      AnalystUser: "Analyst User",
    },
    Satellite: "Satellite",
    SatelliteSensor: "Satellite Sensor",
    SatelliteTarget: "Satellite Target",
    SaveChanges: "Save changes",
    SaveNewPassword: "Save new password",
    ScheduledMaintenance: {
      // Scheduled Maintenance window is hiding if Item1 is empty
      // 2nd and 3rd section are hiding if Item2 and Item3 are empty
      Title: "Scheduled Maintenance",
      Item1: "", // System Update: Thursday Sep 9th, 9am to 11am EDT
      Item2: "", // IT Maintenance: Saturday July 14th, 8am to 12pm EDT
      Item3: "", // Another upcoming maintenance
    },
    selected: "selected",
    SendEmail: "Send Email",
    SendNewVerificationCode: "Send New Verification Code",
    Sensor: "Sensor",
    Search: "Search",
    SearchUsers: "Search for name or email",
    SeeZone: "See Zone",
    SelectFrom: "Select from",
    SendResetLink: "Send reset link",
    searchInputPlaceholder: "Search for sites, date, risk ...",
    SearchForSiteName: "Search for site name",
    SearchForNearbySite: "Search for nearby sites",
    SelectAMonth: "Select a Month",
    ShowSitesLayer: "Show Sites layer",
    SinceLastObservation: "Since Last Observation",
    Site: "Site",
    SiteList: "Site List",
    SiteTypes: {
      midstream: "Mid-stream",
      well: "Well",
      waste: "Waste",
      "oil & gas - well": "Well",
      mining: "Mining",
      agriculture: "Agriculture",
      "power Generation": "Power Generation",
      Agriculture: "Agriculture",
      Midstream: "Mid-stream",
      Mine: "Mine",
      Power: "Power",
      Other: "Other",
      Waste: "Waste",
      Well: "Well",
    },
    Sites: "Sites",
    SitesContained: "Site(s) Contained",
    SitesCovered: "Site(s) Covered By This Observation",
    SitesClusters: "Sites Clusters",
    SitesOfInterest: "Sites of Interest",
    SitesLayerIsHidden: "Sites layer is hidden",
    SiteObservation: "Site Observation",
    Source: "Source",
    SeeOnMap: "See On Map",
    Settings: "Settings",
    ShareView: {
      LabelEmailAddressShareView: "Send To",
      SeparateByCommaForMultipleAddresses:
        "(To send to multiple recipients, separate the email addresses by a comma (,).)",
      LabelMessage: "Optional Message",
      title: "Share This View",
      overview: "Map View",
      Share: "Share",
      ShareLinkWarning:
        "Share Link to This Map View. Only users with a valid account will be able see this view in Spectra.",
      warning:
        "You are about to send this view to someone, please be sure not to share secure information.",
      SharedUrl: "Shared Link",
      Success: "Map shared with",
    },
    Showing: "Showing",
    ShowLayers: "Show Layers",
    ShowOnMap: "Show on Map",
    siteDetails: "Site details",
    SiteName: "Site Name",
    SiteType: "Site Type",
    Status: "Status",
    Step1: "Step 1/3",
    Step2: "Step 2/3",
    Step3: "Step 3/3",
    Success: "Success",
    TableView: "Table view",
    ListView: "List view",
    t: "t",
    ObservationStatus: {
      Yes: "Yes",
      No: "No",
      Maybe: "Maybe",
      Unknown: "Unknown",
    },
    PrivacyPolicy: "Privacy Policy",
    Table: {
      ViewFilters: "Filters",
      ViewTimeline: "Analytics Timeline",
    },
    TermsOfService: {
      FirstParagraph:
        "Spectra Basic is a free-to-use map that helps you see concentrations of methane in the atmosphere worldwide. This tool is designed to raise awareness of, and support discussions about, this potent greenhouse gas.",
      SecondParagraph:
        'By clicking on "I Accept" below, I hereby confirm that I have read and accept the GHGSat ',
      And: "and",
    },
    TermsofUse: "Terms of Use",
    ThereIsNotEnoughDataInChart:
      "There is not enough observation data to render this chart.",
    ThirdPartySatellites: "Third Party Satellites",
    ThirdPartySensor: "Third Party Sensor",
    ThisSiteDoesntExist: "This site doesn't exist",
    Threshold: "Threshold",
    timelines: "Timelines",
    to: "to",
    To: "To",
    Topographic: "Topographic",
    TooltipsInfo: {
      concentration:
        "The volume of methane in the air, measured in parts per billion (ppb)",
      confidence: "Indicates the certainty of the predicted risk",
      demodata:
        "Switching the Demo Data ON will replace the displayed data by a demonstration dataset with Spectra Premium features enabled.",
      emissionObservedOn: "Date when the emission was observed on",
      emissions: "Indicates the amount of methane emitted",
      emissionDate: "Date this emission was found by sensor",
      emissionObservationId:
        "A unique identifier for the observation this emission was derived from",
      emissionSource: "The source sensor where the emission was detected",
      errorRate: "Error rate described as a percentage of rate",
      flares:
        "Indicates whether a flame associated with devices used to burn natural gas has been detected",
      gridDate: "Date range related to the analytics information being shown",
      hotspot:
        "A delta where the detected emission is significant when compared to GHGSat's methane data model",
      lastUpdated: "Last time this location was observed by a GHGSat Sensor",
      latitudeExpect:
        "Expect WGS-84 Latitude (with - sign for South hemisphere).",
      longitudeExpect:
        "Expect WGS-84 Longitude (with - sign for West hemisphere).",
      observations:
        "A satellite measurement taken at a specific site, hover for observation date and emission rate",
      observationDate: "Date this observation was taken by sensor",
      predictionRisk: "Indicates the risk of an emission",
      rate: "Rate of emission",
      satelliteTarget:
        "This is the target of a satellite when taking an observation that covers a larger area",
      sensorName: "Offical name of sensor used",
      siteTypeAndLocation: "Classification of this site and its location",
      sensorNameEmissions: "Name of the sensor that observed this emission",
      siteType: "Classification of this site",
      downloadPartialZones: (amount: number, total: number) =>
        `Download emission data by zone for ${amount} of the past ${total} months`,
      downloadTotalZones: (amount: number) =>
        `Download emission data by zone for the past ${amount} months`,
    },
    TrendAnalysis: "Trend Analysis",
    Type: "Type",
    Undefined: "Undefined",
    UpdatedTimelineFor: "Updated timeline for",
    UnknownEmissions: "Unknown Emission",
    UnsuccessfulLogin: "Unsuccessful login",
    UnsuccessfulLogout: "Unsuccessful logout",
    UserManagement: "User Management",
    UserZones: "User Zones",
    UserRole: "User Role",
    UserRoles: {
      Analyst: "Analyst",
      AnalystDesc: "Can use SPECTRA and see data",
      Manager: "Manager",
      ManagerDesc:
        "Can do everything an Analyst can and use the cart (coming soon)",
      Admin: "Admin",
      AdminDesc: "Can do everything a Manager can and manage other users",
    },
    Validate_Arrow: "Validate",
    VerifyEmail: "Verify Email",
    VerificationCode: "Verification Code",
    VerificationCodeCreate: {
      Instructions:
        "A verification code will be sent to your email address to validate your identity. The code is valid for 24 hours.{br}" +
        "(if you don't see it, check your spam folder).",
      NotReceived: "Didn't receive the email?",
    },
    VerificationCodeInstructions: "Enter your verification code.",
    ViewAllZones: "View All Zones",
    ViewDetails: "Details",
    ViewEmission: "View Emission",
    ViewHistory: "View History",
    ViewSiteDetails: "View Site Details",
    ViewThisSite: "View This Site",
    ViewObservation: "View Observation",
    WasteManagement: "Waste Management",
    Week: "Week",
    Welcome: "Welcome to GHGSat",
    WinningTheRaceTogether: "Winning the race to zero-emissions, together.",
    Year: "Year",
    Yes: "Yes",
    YourEmail: "Your Email",
    YourPassword: "Your Password",
    YourCurrentPassword: "Your current password",
    YourNewPassword: "Your new password",
    YourPasswordsDoNotMatch: "Your passwords do not match.",
    YourPasswordIsNotComplexEnough: "Your password is not complex enough.",
    YourSites: "Your Sites",
    YourSitesEmitting: "Emitting Sites",
    YourSitesEmissions: "Your Site Emissions",
    YourSitesInZone: "In Zone",
    YourZones: "Your Zones",
    Zone: "Zone",
    Zones: "Zones",
    Zone_Zones: "Zone(s)",
    ZoneEmissions: "Zone Emissions",
    ZoomToSite: "Zoom To Site",
    ZoomToEmission: "Zoom To Emission",
  }

  const frenchTranslationsList = {
    ...englishTranslationsList,
    ...{
      BackToLogin: 'Retour à la page de connexion',
      Business: 'Entreprise',
      Cancel: 'Annuler',
      ConfirmNewPassword: 'Confirmation du nouveau mot de passe',
      ConfirmPassword: 'Confirmation du mot de passe',
      ConfirmYourNewPassword: 'Confirmez votre nouveau mot de passe',
      ContactUs: 'Contactez-nous',
      CreateAccount: 'Créer un compte',
      CreateAccountCheckSpam: "Veuillez vérifier votre dossier de courriers indésirables et spams avant de contacter notre équipe d'assistance.",
      CreateAccountConfirmation: 'Confirmation du compte',
      CreateAccountInstructionTitle: "Remplissez le formulaire pour créer un compte gratuit.",
      CreateAccountSuccess: (email: string) => `Nous avons envoyé un e-mail à : ${email}. Dans l'e-mail, vous trouverez un lien qui, lorsque vous cliquerez dessus, vous ramènera au site pour vous connecter.`,
      CreateAccountTerms: { Link: " conditions générales", And: " et ", PrivacyPolicy: "notre politique de confidentialité", Text: "En créant un compte, vous acceptez nos " },
      CreateAnAccount: 'Créer un compte',
      CreatePassword: 'Créer un mot de passe',
      CreatePasswordProblem: "Erreur - Le mot de passe n'a pas été créé.",
      CreatePasswordSuccess: 'Mot de passe créé',
      CreateNewPassword: "Créer un nouveau mot de passe",
      CustomerLoginPrompt: "Êtes-vous un client de GHGSat sous contrat ? Connectez-vous {here}",
      Email: 'Courriel',
      ErrorLoadingLayer: 'Erreur de chargement...',
      Errors: { InvalidEmail: 'Courriel invalide', PasswordMatch: 'Vos mots de passe ne correspondent pas', PasswordIncorrect: 'Mot de passe incorrect', PasswordComplexity: "Votre mot de passe n'est pas assez complexe" },
      FirstName: 'Prénom',
      ForgotPasswordFail: "Erreur - Impossible d'initier un mot de passe oublié",
      ForgotPasswordInstruction: "Saisissez votre adresse courriel ci-dessous et nous vous enverrons un code de vérification afin de confirmer votre identité avant de vous permettre de réinitialiser votre mot de passe.",
      ForgotPasswordSuccess: (email: string) => `Nous avons envoyé un courriel à ${email}. Si vous ne recevez pas d'e-mail, vérifiez que vous avez saisi un compte Spectra valide et soumettez à nouveau votre demande.`,
      ForgotYourPassword: "Mot de passe oublié",
      GoToLogin: 'aller à la page de connexion',
      Here: "ici",
      IAccept: "J'accepte",
      IAgree: "Je suis d'accord",
      IForgotMyPassword: "J'ai oublié mon mot de passe",
      IncorrectEmail: 'Veuillez entrer un courriel valide',
      Industry: 'Industrie',
      IndustryOptions:{
        AcademicResearch:"Universitaire et recherche",
        Agriculture:"Agriculture",
        AluminiumSteel: "Aluminium et acier",
        Cement: "Ciment",
        Chemicals: "Chimie",
        CoalMining: "Charbon",
        Finance:"Finance",
        Government: "Gouvernement",
        Media: "Media",
        NGO: "ONG",
        OilGas: "Pétrole et gaz",
        PowerGeneration: "Production d'électricité",
        ServicesEnvironmentalGIS: "Services - Environnement & SIG",
        Student: "Étudiant",
        Transportation: "Transport",
        WasteManagement: "Gestion des déchets",
        Other: "Autres",
      },
      InvalidInviteLink: { Subtitle: "ce lien d'invitation a expiré ou est invalide. Contactez l'administrateur de votre organisation ou votre représentant commercial pour demander un nouveau lien.", Title: "Le lien d'invitation a expiré ou est invalide" },
      InvalidPasswordResetLink: { Subtitle: "Ce lien de réinitialisation du mot de passe a expiré ou est invalide. Contactez l'administrateur de votre organisation ou votre représentant commercial pour demander un nouveau lien.", Title: 'Le lien de réinitialisation du mot de passe a expiré ou est invalide' },
      JobTitle: 'Titre de poste',
      KeepLoggedIn: "Rester connecté",
      KnowMore: "Vous aimeriez en savoir plus?",
      LastName: 'Nom',
      Login: 'Connexion',
      LogIn: 'Connexion',
      LoginCop27: { ContactUs: 'Contactez-nous', KnowMore: "Vous aimeriez en savoir plus?", PreTitle: "Commencez dès aujourd'hui", ProblemLoggingIn: "Un problème de connexion ? Contactez notre équipe d'assistance", SeeEmissions: "Voir les émissions", ToMitigateThem: "pour les réduire", Title: "Solutions de données pour le changement climatique" },
      MandatoryFields: 'Champs obligatoires',
      NEW: "NOUVEAU",
      NewPassword: 'Nouveau mot de passe',
      NewPasswordSuccess: "Votre nouveau mot de passe est prêt à être utilisé.",
      Password: 'Mot de passe',
      PasswordHints: ["Utilisez 15 caractères ou plus, avec un mélange de lettres minuscules et majuscules et de chiffres.", "N'utilisez pas votre adresse électronique."],
      PoweredBy: "Propulsé par",
      SendNewVerificationCode: "Envoyer un nouveau code de vérification",
      Step1: "Étape 1/3",
      Step2: "Étape 2/3",
      Step3: "Étape 3/3",
      TermsOfService: { And: "et", FirstParagraph: "Spectra Basic est une carte gratuite qui vous aide à visualiser les concentrations de méthane dans l'atmosphère du monde entier. Cet outil est conçu pour sensibiliser et soutenir les discussions sur ce puissant gaz à effet de serre.", IAccept: "J'accepte", SecondParagraph: `En cliquant sur "J'accepte" ci-dessous, je confirme par la présente avoir lu et accepté le GHGSat ` },
      Validate_Arrow: "Validez",
      VerificationCode: "Code de vérification",
      VerificationCodeCreate: { Instructions: "Un code de vérification sera envoyé à votre adresse électronique pour valider votre identité. Ce code est valable pendant 24 heures.{br}(si vous ne le voyez pas, vérifiez votre dossier spam).", NotReceived: "Vous n'avez pas reçu l'e-mail ?" },
      VerificationCodeInstructions: "Saisissez votre code de vérification.",
      VerifyEmail: "Vérifier le courriel",
      YourPasswordIsNotComplexEnough: "Votre mot de passe n'est pas assez complexe.",
      YouPasswordsDoNotMatch: "Vos mots de passe ne correspondent pas.",
      
  },
};
export const Locales: any = new LocalizedStrings({
  en: englishTranslationsList,
  fr: frenchTranslationsList,
});
