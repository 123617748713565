/*
 * Copyright 2022 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 *
 */
/** @format */

import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  confirmSignUp,
  resendCode,
  signUp,
  updateUser,
} from "../../../api/awsAuthApi";
import { NAV_OPTIONS } from "../../../core/constants/Constants";
import { Locales } from "../../../core/constants/Localize";
import { AppContext } from "../../../core/context/app.context";
import {
  getCreateAccountEmail,
  getCreateAccountIsCodeSubmitted,
  getCreateAccountIsEmailVerified,
  getCreateAccountIsSuccess,
  getCreateAccountPassword,
  setCreateAccountEmail,
  setCreateAccountIsCodeSubmitted,
  setCreateAccountIsEmailVerified,
  setCreateAccountIsSuccess,
  setCreateAccountPassword,
} from "../../../core/reducers/createAccount.slice";
import { isPasswordComplexEnough } from "../../../core/utils/User.utils";
import Priv_pdf_en from "../../assets/pdfs/PrivacyPolicy_en.pdf";
import Priv_pdf_fr from "../../assets/pdfs/PrivacyPolicy_fr.pdf";
import TOUpdf_en from "../../assets/pdfs/Terms of Use_en.pdf";
import AddAttributesToAccountForm from "../../components/AddAttributesToAccountForm/AddAttributesToAccountForm";
import LoginCreateBar from "../../components/LoginCreateBar/LoginCreateBar";
import PublicPage from "../../components/PublicPage/PublicPage";
import CodeVerification from "../../components/Reusable/CodeVerification/CodeVerification";
import EmailVerification from "../../components/Reusable/EmailVerification/Emailverification";
import SuccessCreate from "../../components/SuccessCreate/SuccessCreate";
import "./CreateAccount.scss";
import useLocalesReload from "../../../core/hooks/useLocalesReload";
import { useSelectLangSelector } from "../../../core/reducers/lang.slice";

const CreateAccount = () => {
  const { userAttributes, setUserAttributes } = useContext(AppContext);
  const { option } = useParams<{ option: string }>();
  const [hasError, setError] = useState<string | undefined>();
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [title, setTitle] = useState<string>("");
  const [business, setBusiness] = useState<string>("");
  const [industry, setIndustry] = useState<string>("");
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [passwordConfirm, setPasswordConfirm] = useState<string>("");
  const dispatch = useDispatch();
  const email = useSelector(getCreateAccountEmail);
  const password = useSelector(getCreateAccountPassword);
  const isSuccess = useSelector(getCreateAccountIsSuccess);
  const isEmailVerified = useSelector(getCreateAccountIsEmailVerified);
  const isCodeSubmitted = useSelector(getCreateAccountIsCodeSubmitted);
  const language = useSelectLangSelector();

  useLocalesReload();

  useEffect(() => {
    async function checkVerifiedUser(): Promise<void> {
      if (option === NAV_OPTIONS.Verified && userAttributes) {
        if (userAttributes.email_verified) {
          dispatch(setCreateAccountIsEmailVerified(true));
          dispatch(setCreateAccountIsCodeSubmitted(true));
        }
        if (userAttributes.password) {
          dispatch(setCreateAccountPassword(userAttributes.password));
        }
        if (userAttributes.email) {
          dispatch(setCreateAccountEmail(userAttributes.email));
        }
        if (userAttributes.given_name) {
          setFirstName(userAttributes.given_name);
        }
        if (userAttributes.family_name) {
          setLastName(userAttributes.family_name);
        }
        if (userAttributes["custom:business"]) {
          setBusiness(userAttributes["custom:business"]);
        }
        if (userAttributes["custom:job_position"]) {
          setTitle(userAttributes["custom:job_position"]);
        }
        if (userAttributes["custom:industry"]) {
          setIndustry(userAttributes["custom:industry"]);
        }
        // clear the user attributes to avoid keeping the password in context
        setUserAttributes(undefined);
      }
    }
    void checkVerifiedUser();
  }, [setUserAttributes, userAttributes, option, dispatch]);

  useEffect(() => {
    async function checkUnverifiedUser(): Promise<void> {
      if (option === NAV_OPTIONS.Unverified && userAttributes) {
        dispatch(setCreateAccountIsEmailVerified(true));
        if (userAttributes.password) {
          dispatch(setCreateAccountPassword(userAttributes.password));
        }
        if (userAttributes.email) {
          dispatch(setCreateAccountEmail(userAttributes.email));
        }
        // clear the user attributes to avoid keeping the password in context
        setUserAttributes(undefined);
      }
    }
    void checkUnverifiedUser();
  }, [setUserAttributes, userAttributes, option, dispatch]);

  async function handleEmailVerificationForm(
    e: React.FormEvent<HTMLFormElement>
  ) {
    e.preventDefault();
    setError(undefined);
    if (passwordConfirm === password) {
      if (isPasswordComplexEnough(password, email)) {
        await sendEmailVerificationRequest();
      } else {
        setError(Locales.YourPasswordIsNotComplexEnough);
      }
    } else {
      setError(Locales.YourPasswordsDoNotMatch);
    }
  }

  async function sendEmailVerificationRequest() {
    setIsLoading(true);
    try {
      await signUp({
        username: email,
        password: password,
        attributes: { email: email },
      });
      dispatch(setCreateAccountIsEmailVerified(true));
    } catch (e: any) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleCodeVerificationForm(
    e: React.FormEvent<HTMLFormElement>
  ) {
    setError(undefined);
    e.preventDefault();
    await verifyCode();
  }

  async function verifyCode() {
    setIsLoading(true);
    try {
      await confirmSignUp(email, verificationCode);
      dispatch(setCreateAccountIsCodeSubmitted(true));
    } catch (e: any) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleAddAttributesForm(e: React.FormEvent<HTMLFormElement>) {
    setError(undefined);
    e.preventDefault();
    setIsLoading(true);
    try {
      await addAttributes();
      dispatch(setCreateAccountIsSuccess(true));
    } catch (e: any) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function handleResendCode() {
    setIsLoading(true);
    try {
      await resendCode(email);
    } catch (e: any) {
      setError(e.message);
    } finally {
      setIsLoading(false);
    }
  }

  async function addAttributes() {
    await updateUser({
      username: email,
      password: password,
      attributes: {
        given_name: firstName,
        family_name: lastName,
        "custom:job_position": title,
        "custom:business": business,
        "custom:industry": industry,
      },
    });
  }

  const handleSetPasswordConfirm = (value: string) => {
    setPasswordConfirm(value);
    if (hasError !== undefined) {
      setError(undefined);
    }
  };

  return (
    <PublicPage>
      <div className="create-account">
        <LoginCreateBar
          isNewStickerShown={!isSuccess && !isCodeSubmitted && !isEmailVerified}
        />
        {isSuccess && <SuccessCreate text={Locales.CreatePasswordSuccess} />}
        {!isSuccess && isCodeSubmitted && isEmailVerified && (
          <AddAttributesToAccountForm
            onSubmit={handleAddAttributesForm}
            firstName={firstName}
            lastName={lastName}
            title={title}
            business={business}
            industry={industry}
            setFirstName={setFirstName}
            setLastName={setLastName}
            setTitle={setTitle}
            setBusiness={setBusiness}
            setIndustry={setIndustry}
            hasError={hasError}
            isLoading={isLoading}
            showStep
          />
        )}
        {!isSuccess && !isCodeSubmitted && isEmailVerified && (
          <CodeVerification
            onSubmit={handleCodeVerificationForm}
            value={verificationCode}
            onChange={setVerificationCode}
            hasError={hasError}
            loading={isLoading}
            onResendCode={handleResendCode}
            showStep
          />
        )}
        {!isSuccess && !isCodeSubmitted && !isEmailVerified && (
          <>
            <p style={{ textAlign: "center", marginTop: "2rem" }}>
              {Locales.CreateAccountInstructionTitle}
            </p>
            <EmailVerification
              email={email}
              showStep
              hasError={hasError}
              onSubmit={handleEmailVerificationForm}
              password={password}
              passwordConfirm={passwordConfirm}
              setPasswordConfirm={handleSetPasswordConfirm}
              showPasswords
              isLoading={isLoading}
              afterField={
                <>
                  <p className="instructions">
                    {Locales.formatString(
                      Locales.VerificationCodeCreate.Instructions,
                      { br: <br /> }
                    )}
                  </p>
                  <p className="instructions-link">
                    <a href={`mailto:${Locales.GhgSatContactEmail}`}>
                      {Locales.VerificationCodeCreate.NotReceived}
                    </a>
                  </p>
                </>
              }
              beforeStep={
                <>
                  <p className="instructions-link">
                    {Locales.CreateAccountTerms.Text}
                    <a href={TOUpdf_en} target="_blank" rel="noopener noreferrer">
                      {Locales.CreateAccountTerms.Link}
                    </a>
                    {Locales.CreateAccountTerms.And}
                    <a
                      href={(language === 'fr') ? Priv_pdf_fr : Priv_pdf_en}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {Locales.CreateAccountTerms.PrivacyPolicy}
                    </a>
                  </p>
                </>
              }
            />
          </>
        )}
      </div>
    </PublicPage>
  );
};

export default CreateAccount;
