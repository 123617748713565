/*
 * Copyright 2024 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { Dropdown } from 'primereact/dropdown';
import { LANG } from '../../../core/constants/Constants';
import { useAppDispatch } from '../../../core/hooks/useAppDispatch';
import { Locales } from '../../../core/constants/Localize';
import { changeLang, useSelectLangSelector } from '../../../core/reducers/lang.slice';
import './LanguageDropdown.scss';

const LanguageDropdown = () => {
  const dispatch = useAppDispatch();
  const currentLanguage = useSelectLangSelector();

  const languagesList = [
    {
      name: Locales.Languages.fr,
      code: LANG.fr,
    },
    {
      name: Locales.Languages.en,
      code: LANG.en,
    },
  ];

  const getCurrentLang = () => languagesList.find((language) => language.code === currentLanguage);
  return (
    <>
      <Dropdown
        className="language-dropdown"
        value={getCurrentLang()}
        onChange={(e) => dispatch(changeLang(e.value.code))}
        options={languagesList}
        optionLabel="name"
      />
    </>
  );
};

export default LanguageDropdown;