/*
 * Copyright 2024 GHGSat inc.
 * Authors: spectra@ghgsat.com
 * This software is not for distribution outside GHGSat organization
 */

import { useDispatch } from 'react-redux';
import { AppDispatch } from '../reducers/store';

export const useAppDispatch: () => AppDispatch = useDispatch;
